<template dense>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn plain color="#000" @click.stop="usuLogout" v-bind="attrs" v-on="on">
                <v-icon>mdi mdi-{{label.icon}}</v-icon>
            </v-btn>
        </template>
        <span>{{label.label}}</span>
    </v-tooltip>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name:'Logout',
    methods: mapActions(['usuLogout']),
    computed:{
        label(){
            return localStorage.getItem('tokenAccess') ? {icon:'logout',label:'Sair'}:{icon:'login',label:'Entrar'}
        }
    }
   
}
</script>